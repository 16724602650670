import React, { useEffect, useRef, useState } from "react";
import "./GlobalSale.scss";
import { theme, Modal, Form, Typography, Input } from "antd";
import Utils from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { resetError } from "../../features/settings/settingsSlice";

export default function GlobalSale({
  isOpened,
  title,
  onOk,
  onCancel,
  subTitle,
}) {
  const { useToken } = theme;
  const { token } = useToken();
  const { loading, error } = useSelector((state) => state?.settings);
  const {
    info: { organization },
    loading: organizationLoading,
  } = useSelector((state) => state?.me);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [isConfirmOpened, setIsConfirmOpened] = useState(false);

  useEffect(() => {
    if (error && !loading) {
      for (let key in error) {
        if (key === "global_sale") {
          const text = error[key];
          if (
            text &&
            Array?.isArray(text?.amount) &&
            text?.amount[0] &&
            typeof text?.amount[0] === "string"
          ) {
            formRef?.current?.setFields([
              {
                name: key, // Field name you want to set the error for
                errors: [text?.amount[0]], // Assuming your error has a 'message' field
              },
            ]);
          }
        }
      }
    }
  }, [error, loading]);

  return (
    <Modal
      className="globalSale-cont"
      open={isOpened}
      title={title}
      centered={true}
      closeIcon={false}
      okText="Save"
      destroyOnClose={true}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      onCancel={() => {
        onCancel();
        formRef?.current?.resetFields();
        setIsConfirmOpened(false);
        if (error) {
          dispatch(resetError());
        }
      }}
      onOk={() => {
        formRef?.current?.submit();
      }}
      cancelButtonProps={{
        className: "addsalemodal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "addsalemodal-cont__btn",
        loading: loading || organizationLoading,
        disabled: error?.global_sale,
      }}>
      <Form
        className="globalSale-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={() => {
          setIsConfirmOpened(true);
        }}
        onFinishFailed={() => {}}>
        {" "}
        <Form.Item
          className="globalSale-cont__form__formItem"
          name="global_sale"
          initialValue={
            organization?.global_sale?.amount
              ? `${organization?.global_sale?.amount}`
              : ""
          }
          rules={[
            {
              validator: Utils?.saleValidator,
            },
          ]}
          label={
            <Typography.Text className="globalSale-cont__form__formItem__text">
              {subTitle ? subTitle : ""}
            </Typography.Text>
          }
          colon={false}>
          <Input
            placeholder={subTitle ? subTitle : ""}
            disabled={loading || organizationLoading}
            onChange={() => {
              if (error && error["global_sale"]) {
                dispatch(resetError());
                formRef?.current?.setFields([
                  {
                    name: "global_sale",
                    errors: [], // Clear the error
                  },
                ]);
              }
            }}
          />
        </Form.Item>
      </Form>
      <ConfirmModal
        isOpened={isConfirmOpened && isOpened}
        title={"Are you sure"}
        subTitle={
          "You want to change global sale amount ? After changing the amount, it will be applied to all items in all submenus."
        }
        icon={
          <ExclamationCircleFilled
            style={{
              color: token?.colorWarning,
              fontSize: "24px",
            }}
          />
        }
        onCancel={() => {
          setIsConfirmOpened(false);
        }}
        onOk={() => {
          setIsConfirmOpened(false);
          const values = formRef?.current?.getFieldsValue();
          onOk(
            {
              global_sale: {
                amount: values?.global_sale ? values?.global_sale : undefined,
              },
            },
            () => {
              onCancel();
              formRef?.current?.resetFields();
              setIsConfirmOpened(false);
              if (error) {
                dispatch(resetError());
              }
            }
          );
        }}
      />
    </Modal>
  );
}
